<template>
  <div>
    <a-form ref="form" layout="vertical" :model="form" class="formCon">
      <a-row>
        <a-col :span="12">
          <a-form-item label="客户姓名" name="name" :rules="[{ required: true, message: '请输入客户姓名' }]">
            <a-input allow-clear v-model:value.trim="form.name" placeholder="客户姓名">
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="微信号" name="wechat" :rules="[{ required: true, message: '请输入微信号' }, {
      pattern: /^[a-zA-Z-_0-9]([-_a-zA-Z0-9]{5,19})+$/,
      message: '请输入正确的微信号'
    }]">
            <a-input @blur="blurChange($event, wxblurconfig)" allow-clear v-model:value.trim="form.wechat"
              placeholder="微信号">
            </a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="手机号" name="phoneNumber" :rules="[{ required: true, message: '请输入手机号' }, {
      pattern: /^1\d{10}$/,
      message: '请输入正确的手机号'
    }]">
            <a-input @blur="blurChange($event, phoneblurconfig)" allow-clear v-model:value.trim="form.phoneNumber"
              placeholder="手机号">
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="客户星级" name="star">
            <a-select v-model:value="form.star" placeholder="请选择客户星级" allow-clear style="width: 100%">
              <a-select-option v-for="option in starRating" :key="option.id" :name="option.name"
                :disabled="option.disabled">{{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="签单时间" name="signingTime" :rules="[{ required: true, message: '请选择签单时间' }]">
            <a-date-picker :locale="locale" :disabled-date="disabledDate" v-model:value="form.signingTime"
              placeholder="请选择签单时间" style="width: 100%" :getPopupContainer="triggerNode => {
      return triggerNode.parentNode || document.body;
    }" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="服务项目" name="serviceItemId" :rules="[{ required: true, message: '请输入服务项目' }]">
            <a-select v-model:value="form.serviceItemId" placeholder="请选择服务项目" allow-clear style="width: 100%">
              <a-select-option v-for="option in signingItemList" :key="option.id" :name="option.content"
                :disabled="option.disabled">{{ option.content }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="订单金额" name="orderAmount" :rules="[{ required: true, message: '请输入订单金额' }]">
            <a-input-number prefix="￥" style="width: 100%;" v-model:value="form.orderAmount" placeholder="输入订单金额">
            </a-input-number>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="产品类型" name="productType" :rules="[{ required: true, message: '请输入产品类型' }]">
            <a-select v-model:value="form.productType" placeholder="请选择产品类型" allow-clear style="width: 100%">
              <a-select-option v-for="option in productType" :key="option.id" :name="option.name"
                :disabled="option.disabled">{{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="订单归属人" name="attributionId" :rules="[{ required: true, message: '请输入订单归属人' }]">
            <a-select v-model:value="form.attributionId" placeholder="请选择订单归属人" allow-clear style="width: 100%">
              <a-select-option v-for="option in attributablePersonList" :key="option.id" :name="option.name"
                :disabled="option.disabled">{{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row v-for="(item, index) in form.followUpWithPeople" :key="index">
        <a-col :span="8">
          <a-form-item :name="item.collaboratorId + index" >
            <div class="label-style" slot="label">
              <div>
                <span v-if="index == 0">订单跟进人</span>
                <span v-else>合作跟进人{{ index }}</span>
                <DeleteOutlined v-if="index > 0" @click="deleteCoop(index)"
                  style="color: #1677ff; font-size: 16px; margin-left: 6px; cursor: pointer;" />
              </div>
            </div>
            <a-select v-model:value="item.collaboratorId" placeholder="请选择合作跟进人" style="width: 100%">
              <a-select-option v-for="option in followUpPeopleIdList" :key="option.id" :name="option.name"
                :disabled="option.disabled">{{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item label="提成分配系数" :name="item.commissionDistributionRate + index">
            <a-input-number addon-after="%" v-model:value="item.commissionDistributionRate"></a-input-number>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-button @click="addCoop" type="primary" ghost style="margin-bottom: 20px;">添加合作跟进人</a-button>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-item label="订单情况" name="orderStatus"
            :rules="[{ required: true, message: '请输入订单情况' }, { min: 1, max: 500, message: '只能输入1-500个字符' }]">
            <a-textarea allow-clear v-model:value.trim="form.orderStatus" placeholder="订单情况">
            </a-textarea>
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item>
        <a-button type="primary" @click="confirm" :loading="submitLoading">保存</a-button>
      </a-form-item>
    </a-form>
    <a-modal v-model:open="openModal" title="提示" @ok="handleOk">
      <p class="am-content">{{ modalValue }}</p>
    </a-modal>
    <a-modal v-model:open="openWarnModal" title="警告" @ok="submit">
      <p class="am-content">{{ modalWarnValue }}</p>
    </a-modal>
  </div>
</template>

<script>
import TablePage from "@/components/TablePage";
import { starRating, productType } from '@/utils/public';
import dayjs from 'dayjs';
export default {
  components: { TablePage },
  props: ['selectedRows'],
  name: "institutionsCustomerDetail",
  data() {
    return {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      form: {},
      starRating: starRating,
      productType: productType,
      openModal: false,
      showModal: false,
      modalTitle: '',
      modalContent: '',
      counting: false,
      countdown: 5,
      countdownInterval: null,
      startDisabled: false,
      isComfirm: false,
      domain: {},

      attributablePersonList: [],
      followUpPeopleIdList: [],
      signingItemList: [],
      orderAmount: [],
      signingItemList: [],
      source: {},

      wxblurconfig: {
        title: '提示',
        submitUrl: '/institutions/customer/wechat',
        key: "wechat",
        needId: true,
        blurMessage: (res) => {
          return `客户与  ${res.entryPerson} 添加的 ${res.institutionsCustomer}  重复！ 当前客资归属人 ${res.attributablePerson} ， 
                  客户阶段为 ${{
              TO_BE_FOLLOWED_UP: "待跟进",
              IN_FOLLOW_UP: "跟进中",
              HAS_BECOME_A_SINGLE: "已成单",
              MULTIPLE_ORDERS: "多次成单",
              ACCURATE_CUSTOMER_FUNDING: '精准客资'
            }[res.customerPhase]}，创建时间：${res.createdDate.split('T')[0]}`
        },
      },

      phoneblurconfig: {
        title: '提示',
        submitUrl: '/institutions/customer/phoneNumber',
        key: "phoneNumber",
        needId: true,
        blurMessage: (res) => {
          return `客户与  ${res.entryPerson} 添加的 ${res.institutionsCustomer}  重复！ 当前客资归属人 ${res.attributablePerson} ， 
                  客户状态为 ${{
              TO_BE_FOLLOWED_UP: "待跟进",
              IN_FOLLOW_UP: "跟进中",
              HAS_BECOME_A_SINGLE: "已成单",
              MULTIPLE_ORDERS: "多次成单",
              ACCURATE_CUSTOMER_FUNDING: '精准客资'
            }[res.customerPhase]}，创建时间：${res.createdDate.split('T')[0]}`
        },
      },

      openModal: false,
      modalValue: '',
      openWarnModal: false,
      modalWarnValue: ''
    }
  },
  created() {
    this.getDetail(this.selectedRows[0].id)
  },
  methods: {
    disabledDate(time) {
      return time > Date.now();
    },

    async getDetail() {
      try {
        const res = await this.$http.get('/institutions/customer/addOrder/get', {
          id: this.selectedRows[0].id,
        });
        this.attributablePersonList = res.attributablePersonList;
        this.followUpPeopleIdList = res.followUpPeopleIdList;
        this.signingItemList = res.signingItemList;
        this.orderAmount = res.orderAmount;
        this.signingItemList = res.signingItemList;
        this.form = res.source;

        if (!this.form.followUpWithPeople) {
          this.form.followUpWithPeople = [{
            collaboratorId: null,
            commissionDistributionRate: 100
          }];
        }
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    filterSouOption(input, option) {
      return option.text.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0;
    },

    changeContent(e) {
      this.currContent = this.pushContent.filter(item => item.id == e)[0];
    },

    async confirm() {
      try {
        await this.$refs.form.validate();
      } catch (e) { // 未通过表单验证
        return;
      }
      let type = this.form.productType == 0 ? '标准产品' : '非标产品';
      this.modalWarnValue = `该订单的产品类型为【${type}】 一旦确定无法修改，确定提交订单吗？`
      this.openWarnModal = true;
    },

    async submit() {
      this.openWarnModal = false;
      this.submitLoading = true;
      let signingTime = dayjs(this.form.signingTime).format('YYYY-MM-DD HH:mm:ss');
      let { followUpWithPeople, productType, name, wechat, phoneNumber, star, serviceItemId, orderAmount, attributionId, followUpWithPeopleId, orderStatus } = this.form
      try {
        await this.$http.post('/institutions/customer/addOrder', { id: this.selectedRows[0].id, signingTime, followUpWithPeople: JSON.stringify(followUpWithPeople), productType, name, wechat, phoneNumber, star, serviceItemId, orderAmount, attributionId, followUpWithPeopleId, orderStatus });
        this.$message.success('提交成功');
        this.$refs.form.resetFields();
        this.$emit('close');
      } catch ({ message }) {
        this.$message.error(message);
      }
      this.submitLoading = false;
    },

    async blurChange(e, info) {
      let { submitUrl, key, needId, title, blurMessage } = info
      let value = e.target._value;
      try {
        let res = await this.$http.get(submitUrl, { [key]: value, id: needId ? this.selectedRows.map(({ id }) => id) : null });
        if (res.code == '200') {
          this.openModal = true;
          let message = ''
          if (typeof blurMessage === 'function') {
            message = blurMessage(res);
          }
          this.modalValue = message;
        }
      } catch ({ message }) {
        //this.$message.error(message);
      }
    },

    handleOk() {
      this.openModal = false;
    },

    addCoop() {
      if (this.form.followUpWithPeople.length < 4) {
        this.form.followUpWithPeople.push({
          collaboratorId: null,
          commissionDistributionRate: 100
        })
      } else {
        return
      }
    },

    deleteCoop(index) {
      this.form.followUpWithPeople.splice(index, 1)
    }
  }
}
</script>

<style scoped lang="scss">
.blueCopy {
  color: blue;
  text-decoration: underline;
}

.ic-title {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 20px;
}

.formCon {
  .ant-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    .ant-col {
      width: 48%;
      margin: 0 5px;
      flex: 1;
    }
  }
}

.label-style {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ls-required {
  color: red;
  margin-right: 4px;
}

.ls-icon {
  margin-left: 4px;
  font-size: 14px;
  color: #999999;
}

.ma-col {
  border: 1px solid #d9d9d9;
  padding: 20px 20px 0 20px;
  border-radius: 8px;

  .ms-flex {
    display: flex;
    margin-bottom: 20px;
  }

  .ms-title {
    width: 90px;
    text-align: right;

    flex-shrink: 0;
  }

  .ms-content {
    flex-shrink: 0;
    width: calc(100% - 130px);
  }

  .ms-scroll {
    flex-shrink: 0;
    width: calc(100% - 130px);
    max-height: 200px;
    overflow-y: scroll;
  }

  .ms-img {
    width: 160px;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: none;
  /*可以设置轨道颜色*/
  padding-right: 2px;
}

/*滚动条*/
::-webkit-scrollbar-thumb {
  background: rgba(142, 142, 142, 0.4);
  border-radius: 6px;
}
</style>
